<template>
  <div class="clearfix">
    <a-upload 
      :action="api" 
      :data="{path}"
      name="files"
      :accept="accept"
      list-type="picture-card" 
      :file-list="fileList" 
      :before-upload="beforeUploadImg"
      :multiple="multiple"
      @preview="handleImgPreview" 
      @change="handleUploadImgChange"
      >
      <div v-if="!fileList || (fileList.length < limit)">
        <a-icon type="plus"/>
      </div>
    </a-upload>
    <a-modal :visible="previewImgVisible" :footer="null" @cancel="handleImgPreviewCancel">
      <img alt="产品图片" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "UploadImgs",
  props: {
    path: { type: String, default: "image/" },
    api: { type: String, default: `${process.env.VUE_APP_IMG_API}/upload/imgs` },
    accept: { type: String, default: "image/png,image/jpg,image/jpeg" },
    fileList: { type: Array, default: [] },
    limit: { type: Number, default: 3 },
    multiple: { type: Boolean, default: false }
  },
  data() {
    return {
      previewImgVisible: false,
      previewImage: '',
    }
  },
  methods: {

    beforeUploadImg(file) {
      const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('只支持 jpg,jpeg,png 格式！');
      }
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error('文件大小不能超过 10MB!');
      }
      return new Promise((resolve, reject) => {
        isJpgOrPng && isLt20M?resolve(isJpgOrPng && isLt20M):reject(isJpgOrPng && isLt20M);
      });
    },

    handleImgPreviewCancel() {
      this.previewImgVisible = false;
    },

    handleUploadImgChange({ fileList, file }) {
      // this.fileList = fileList;
      let urls = [];
      if(file.status === 'done') {
        console.log("上传的文件列表 ==> ", fileList);
        urls = fileList.map(item => {
					if(!item.response){
						item.response = [{
							"filePath": item.url.replace(this.$store.state.baseinfo.filedomain, ""),
							"url": item.url
						}];
					}
					return item.response[0];
				});
      }
      this.$emit("_change", fileList, urls);
    },

    async handleImgPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewImgVisible = true;
    }
  },
  // watch: {

  // }  
}
</script>